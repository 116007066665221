import React,{ useRef, useState, useEffect } from "react"
import { Link } from 'gatsby'
import { usePrismCategories } from '../hooks/use_prims_categories';
import { usePrismModels } from '../hooks/use_prism-models';
import "../styles/_layout/header/header.scss"
import CalorexLogoHeader from '../components/svg/commons/calorex_logo_header';
import CssClassManager from '../components/_helpers/_css_class_manager'

import SvgIconShop from '../components/svg/shop_online/shop_online_icon'

const Header = ({handleMenuModels,header_visible=false}) => {
  const allCategories = usePrismCategories().nodes;
  const allModels = usePrismModels().nodes;
  const [selectedModels, setSelectedModels] = useState([]);
  const [bottomActive, setBottomActive] = useState(false);
  const headerBottomRef = useRef(null);
  const open_burger_button = useRef(null);
  const menu_hidden = useRef(null);

  let valueScroll = 0;
  let cssClassManager = new CssClassManager()

  useEffect(() => {
    if(bottomActive) {
      headerBottomRef.current.style.height = '100px';
    } else {
      headerBottomRef.current.style.height = '0';
      headerBottomRef.current.style.paddingTop = '0px';
      setTimeout(() => {
        setSelectedModels([])
      }, 150);
    }
  }, [bottomActive])

  function get_top_links(){

    return allCategories.map(category => (
        <div className={`header_top_nav_item  ${category.uid === "fortis" ? "is-fortis-item" : ""}`} key={category.uid} onMouseEnter={() => handleClickCategory(category)}>
          <p>{category.data.name.text}</p>
        </div>
    ))
  }

  function handleClickCategory (category) {
    const selectedModels = allModels.filter(model => model.data.category.uid === category.uid);
    setBottomActive(true);
    setSelectedModels(selectedModels);
  }

  function open_burger(models){
    let element, name, arr;
    element = document.getElementsByTagName("BODY")[0];
    name = "body--onburger";
    arr = element.className.split(" ");
    if (arr.indexOf(name) === -1) {
        element.className += " " + name;
    }
    handleMenuModels(models);
  }

  useEffect(()=>{
    if(!header_visible){
      window.addEventListener('scroll', listenToScroll)
    }

  })

  function listenToScroll () {
    let windowY = window.pageYOffset;

    if( windowY > 100){
      if(windowY > valueScroll){
        cssClassManager.add_class(menu_hidden.current,'is-hidden');
      }else{
        cssClassManager.remove_class(menu_hidden.current,'is-hidden');
      }
      valueScroll = windowY
    }
}



  return (
    <header className="main_header" onMouseLeave={() => setBottomActive(false)} ref={menu_hidden}>
      <div className="header_top">
        <Link to='/' className='header-link'>
          <div className="header_logo_container">
            <CalorexLogoHeader />
          </div>
        </Link>
        <div className="header_top_nav_container">
          {
            get_top_links()
          }
        </div>
        <div className="header_menu_models">
          <div className="header_menu_models-link" onClick={()=>open_burger(true)}>Modelos</div>
        </div>
        <Link to='/compra-en-linea' className="header_menu_shop">
          <SvgIconShop/>
        </Link>
        <div className="header_hamburguer_container" ref={open_burger_button} onClick={()=>open_burger(false)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div ref={headerBottomRef} className="header_bottom">
        <div className="header_bottom_nav_container">
          {
            selectedModels.map(model => (
              <Link to={`/calentadores/${model.uid}`} className={`header_nav_bottom_item ${model.data.category.uid === 'electricos' ? 'electricos' : null}`} key={model.uid} activeClassName="active" >
                <div className="header_nav_bottom_item_front"></div>
                { model.data.svg_icon ? <div className={"header-svg__container"} dangerouslySetInnerHTML={{__html: model.data.svg_icon}} /> : null}
                <p>{model.data.model_name.text}</p>
              </Link>
            ))
          }
        </div>
      </div>
    </header>
    )
}



export default Header
