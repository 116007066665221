import React  from "react";

//import styles from '../../styles/_components/_chat/_chat.module.scss'

const Chat=()=>{    

    // function open_chat()
    // {
    //     if(navigator.userAgent.toLowerCase().indexOf('opera') !== -1 && window.event.preventDefault) window.event.preventDefault(); 
    //     let chat = window.open('https://savethechildrenv5.zw-callitonce.alestra.net.mx/', 'S1Gateway', 'toolbar=0,scrollbars=0,location=0,status=0,menubar=0,width=402,height=590,resizable=0'); 

    //     chat.focus(); 
    //     var windowwidth = 402; 
    //     var windowheight = 590; 
    //     var screenwidth = window.screen.availWidth; 
    //     var screenheight = window.screen.availHeight; 
    //     chat.moveTo(screenwidth - windowwidth,screenheight - windowheight);
    //     chat.opener=window;
    //     return false
    // }




    return(
        <> 
          {/*
            <Helmet>
                <link type="text/css" rel="stylesheet" href="https://savethechildrenv5.zw-callitonce.alestra.net.mx/clientweb/chat-client-v4-STC/css/form.css" />
                <script type="text/javascript" dangerouslySetInnerHTML={{
                    __html: `
                        SERVICE_PATTERN_CHAT_CONFIG = {
                            appId: '7b13c05c3b624c7eac27ab16b4fcc090',
                            apiUrl: 'https://savethechildrenv5.zw-callitonce.alestra.net.mx/clientweb/api/v1',
                            tenantUrl: 'savethechildrenv5.zw-callitonce.alestra.net.mx',
                            chatPath: 'https://savethechildrenv5.zw-callitonce.alestra.net.mx/clientweb/chat-client-v4-STC/'
                                    
                                
                            
                        };
                    `
                }}>
                </script>
                <script src="https://savethechildrenv5.zw-callitonce.alestra.net.mx/clientweb/chat-client-v4-STC/js/init.js"></script> 
            </Helmet>
          } 
         <div className={styles.oChat_container}>
            <div className={styles.oChat_button} onClick={()=>open_link()}>
                <div className={styles.oChat_points_container}>
                    <div className={styles.oChat_point}></div>
                    <div className={styles.oChat_point}></div>
                    <div className={styles.oChat_point}></div>
                </div>
                <p className={styles.oChat_text}>¡Hola! ¿Tienes una duda?</p>
            </div>
        </div>  */}
        </>
       
    )
}
export default Chat