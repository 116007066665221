import React from "react"

const SvgIconShop =  props => (
    <svg id="prefix__Layer_1" x={0} y={0} viewBox="0 0 100 100" xmlSpace="preserve" {...props} >
        <style>{".prefix__st0{fill:#202632}"}</style>
        <path className="prefix__st0"
            d="M44 19l1 2-1 5h-1l-1-2-1-9c0-3 0-7 3-9l1 2c-2 3-1 7-1 10v1zm17 7h-2l-1-9c0-2-2-10-9-10l-1-2h1c7 0 10 7 11 8 2 5 2 9 1 13zm7 0s-3 68-5 72l-53-7 4-64 12-1v1l2 5 2 1h2l1-2-2-4v-1h13l-1 6 1 1 1 1h1l2-2 1-6h19zm22 65L67 80l3-54 7 1 7-1h1l5 65zm-1 1l-12 2-12 4 1-17 23 11z"
        />
        <path className="prefix__st0"
            d="M49 13l-1 10v3l-1 6v1h-2l-1-1 1-6v-3l1-1V8l-1-2-4-2h-1c-9 0-10 9-10 11v12l2 5-2 1-1-1-2-5c-1-5-1-10 1-16 1-2 4-9 12-9h1c3 0 5 1 6 3h1l1 2v6z"
        />
    </svg>
)

export default SvgIconShop