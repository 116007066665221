import React from "react"

const SvgYoutube = props => (
    <svg viewBox="0 0 176 124" {...props}>
        <g data-name="Layer 2">
            <path
            d="M172 19a22 22 0 00-15-15c-14-4-69-4-69-4S33 0 19 4A22 22 0 004 19C0 33 0 62 0 62s0 29 4 43a22 22 0 0015 15c14 4 69 4 69 4s55 0 69-4a22 22 0 0015-15c4-14 4-43 4-43s0-29-4-43zM70 88V36l46 26z"
            data-name="Layer 1"
            fill="red"
            />
        </g>
    </svg>
)

export default SvgYoutube
