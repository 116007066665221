export class CssClassManager{
    element = null;
    
    add_class(_element,_class){
        if(_element===null) return false;
        let element, name, arr;
        element = _element;        
        
        name = _class;
        arr = element.className.split(" ");
        if (arr.indexOf(name) === -1) {
            let classes = element.className.trim();
            element.className =classes+" "+name;
        }
    }
    remove_class(_element,_class){
        if(_element===null) return false;
        let element = _element
        
        var re = new RegExp(`\\b${_class}\\b`, "g");
        
        element.className = element.className.replace(re, "");
    }
    has_class(_element,_class){
        if(_element===null) return false;
        let element, name, arr;
        element = _element;        
        
        name = _class;
        arr = element.className.split(" ");
        
        return (arr.indexOf(name) !== -1) 
    }
}
export default CssClassManager;