import React from 'react'

const CalorexLogo = props => (
  <svg viewBox="0 0 525.59 77.62" {...props}>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m34.73,10.36c5.33,4.77,23.6,21.02,26.43,23.55v35.09H8.78v-35.14c2.78-2.51,20.65-18.71,25.95-23.51m-3.47-9.04L1.68,28.13c-1.05.97-1.68,2.36-1.68,3.8v40.56c0,2.84,2.3,5.13,5.13,5.13h59.7c2.82,0,5.12-2.29,5.12-5.13V31.93c0-1.44-.62-2.85-1.72-3.84L38.12,1.3c-1.95-1.74-4.91-1.72-6.86.03"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m34.75,29.76c-2.45,2.21-10.69,9.68-11.96,10.82v16.18h24.15v-16.16c-1.33-1.17-9.75-8.67-12.19-10.85"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m399.01,45.51c0-9.61-6.34-15.57-16.55-15.57h-33.97v47.52h10.67v-16.97h17.27l11.96,16.97h12.5l-12.5-18.05c6.79-1.68,10.63-6.84,10.63-13.9m-18.01,5.69h-21.85v-11.4h21.81c6.44,0,7.21,2.92,7.21,5.68s-.84,5.72-7.17,5.72"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m186.45,28.13c-2.21,0-4.36,1.02-5.42,2.71l-28.19,46.24h12.69l5.16-8.67h31.54l5.16,8.67h12.69l-28.2-46.24c-1.03-1.69-3.2-2.71-5.41-2.71m-10.55,31.54s10.18-17.16,10.55-17.8c.39.64,5.47,9.23,10.56,17.8h-21.12Z"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m308.22,28.37c-18.68,0-29.03,4.04-29.03,24.63s10.35,24.62,29.03,24.62,29.04-4.03,29.04-24.62-10.37-24.63-29.04-24.63m0,39c-11.53,0-17.55-.62-17.55-14.36s6.02-14.38,17.55-14.38,17.55.64,17.55,14.38-6,14.36-17.55,14.36"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m139.77,61.2c-2.27,5.82-7.78,6.17-16.37,6.17-11.53,0-17.55-.62-17.55-14.36s6.02-14.38,17.55-14.38c8.58,0,14.09.35,16.36,6.17l10.61-4.69c-3.88-9.51-13.15-11.73-26.98-11.73-18.68,0-29.03,4.04-29.03,24.63s10.35,24.62,29.03,24.62c13.83,0,23.1-2.22,26.98-11.73l-10.61-4.69Z"/>
    
    <polygon fill={props.fill != null ? props.fill :"#D31347"} points="237.13 29.93 236.1 29.93 226.33 29.93 226.33 77.45 273.43 77.45 273.43 76.42 273.43 66.65 237.13 66.65 237.13 29.93"/>
    <polygon fill={props.fill != null ? props.fill :"#D31347"} points="422.63 58.26 447.23 58.26 447.23 57.23 447.23 47.46 422.63 47.46 422.63 40.73 457.4 40.73 457.4 39.71 457.4 29.93 411.84 29.94 411.84 77.45 458.94 77.45 458.94 76.42 458.94 66.65 422.63 66.65 422.63 58.26"/>
    <polygon fill={props.fill != null ? props.fill :"#D31347"} points="525.59 29.93 511.52 29.93 495.55 46.43 479.58 29.93 465.51 29.93 488.51 53.7 465.51 77.45 479.62 77.45 495.55 60.98 511.49 77.45 525.59 77.45 502.59 53.7 525.59 29.93"/>
  </svg>
)

export default CalorexLogo