import { useStaticQuery, graphql } from "gatsby"

export const usePrismModels = () => {
    const prismic = useStaticQuery(
        graphql`
            query{
                allPrismicModel(
                        sort: {fields: [data___category___uid, data___order], order: ASC }
                    ) {
                    nodes{
                        uid
                        data{
                            model_name {
                                text
                                html
                            }
                            title_color_start
                            title_color_end
                            svg_icon
                            category{
                            uid
                            }
                            body{
                                __typename
                                ... on PrismicModelBodyUsosIdeales{
                                    items{
                                        description{
                                            html
                                        }
                                    }
                                }
                                ...on PrismicModelBodyArchivos{
                                    primary{
                                        technical{
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    return prismic.allPrismicModel
}
