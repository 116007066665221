import { useState, useEffect } from 'react';
import { isNullOrUndefined } from 'util';

export const DEVICES = {
    mobile: "mobile",
    tablet: "tablet",
    tabletLandscape: "tabletLandscape",
    desktopMin: "desktopMin",
    desktop: "desktop"
};

export function useCurrenDevice(callback) {

    const [device, setDevice] = useState(null);
    const [isFirsTime, setIsFirsTime] = useState(true);

    function getDevice() {
        const width = window.innerWidth;

        if(width < 768) {
            setDevice(DEVICES.mobile);
        } else if (width < 1024) {
            setDevice(DEVICES.tablet);
        } else {
            setDevice(DEVICES.desktop);
        }

        // if(width > 768 && width < 1260){
        //     setDevice(DEVICES.tabletLandscape);
        // }
        // if(width >= 1260 ){
        //     setDevice(DEVICES.desktopMin)
        // }
    }

    useEffect(() => {
        window.addEventListener("resize", getDevice);
        if(isFirsTime) {
            getDevice();
            setIsFirsTime(false);
        }
        if(!isNullOrUndefined(callback)){
            callback();
        }
        return () => window.removeEventListener("resize", getDevice);
        // console.log('state callback', !isNullOrUndefined(callback))
        // eslint-disable-next-line
    }, [device]);

    return [device];
}