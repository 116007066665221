import React from 'react'
import {Link} from 'gatsby'
import "../styles/_layout/footer/footer.scss"
import CalorexLogo from '../components/svg/commons/calorex_logo';
import SvgTwitter from '../components/svg/footer/twitter'
import SvgFacebook from '../components/svg/footer/facebook'
import SvgInstagram from '../components/svg/footer/instagram'
import SvgYoutube from '../components/svg/footer/youtube'

function footer() {
    return (
        <footer>
            <div className="footer__container">
                <div className="footer__logo--container">
                    <CalorexLogo />
                </div>
                <div className="footer__social">
                    <a href="https://twitter.com/calorexmx" target="_blank" rel="noopener noreferrer"><SvgTwitter/></a>
                    <a href="https://www.facebook.com/calorex" target="_blank" rel="noopener noreferrer"><SvgFacebook/></a>
                    <a href="https://www.youtube.com/channel/UCEktYJj53fTESV3rr4llTPg" target="_blank" rel="noopener noreferrer"><SvgYoutube/></a>
                    <a href="https://www.instagram.com/calorex_oficial/" target="_blank" rel="noopener noreferrer"><SvgInstagram/></a>
                </div>
                <div className="legal__container">
                    <span>Calentadores de América S.A de C.V</span>
                    <span className='separator__space'>|</span>
                    <Link to="/aviso-de-privacidad"><span>Aviso de privacidad</span></Link>
                </div>
            </div>
        </footer>
    )
}

export default footer
