import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import styles from "../styles/_layout/burger/burger.module.scss"
import CloseIcon from "../components/svg/commons/close_icon"
import { usePrismCategories } from "../hooks/use_prims_categories"
import { usePrismModels } from "../hooks/use_prism-models"
import { useCurrenDevice, DEVICES } from "../hooks/useCurrentDevice"
import CalorexLogoHeader from "../components/svg/commons/calorex_logo_header"

const BurgerMenu = ({ isMenuModels }) => {

  const categories = usePrismCategories().nodes
  const models = usePrismModels().nodes
  const [linksCategories, setLinksCategories] = useState([])
  const [device] = useCurrenDevice()

  function get_item(_text, _to, _tag, _key = Math.random()) {
    return (
      <Link
        key={`link-${_key}`}
        className={`o-burguer__menu__item ${styles.oBurguer__menu__item} ${_tag}`}
        to={_to}
        onClick={() => close_burger()}
      >
        <p>{_text}</p>
        <div className={styles.oBurguer__menu__spacer}></div>
      </Link>
    )
  }
  function close_burger() {
    let element
    element = document.getElementsByTagName("BODY")[0]
    element.className = element.className.replace(/\bbody--onburger\b/g, "")
  }

  useEffect(() => {
    if (device === DEVICES.desktop) return

    let links = []
    categories.map(category => {
      const firstLink = models.filter(
        model => model.data.category.uid === category.uid
      )[0]

      if (firstLink === undefined) return false
      return links = [
        ...links,
        {
          name: category.data.name.text,
          url: firstLink.uid,
        },
      ]
    })
    setLinksCategories(links)
  }, [device, categories, models])
  return (
    <div className={`o-burguer__menu ${styles.oBurguer__menu}`}>
      <div className={styles.oBurguer__menu__stage}>
        <div className={styles.oBurguer__menu__header}>
          <Link to="/" className="header-link">
            <div className="header_logo_container">
              <CalorexLogoHeader />
              {/* <p className="header_logo_copy">Un buen baño te cambia el día</p> */}
            </div>
          </Link>
          <div
            className={styles.oBurguer__menu__close}
            onClick={() => close_burger()}
          >
            <CloseIcon></CloseIcon>
          </div>
        </div>
        <div className={styles.oBurguer__menu__body}>
          {isMenuModels ? (
            <>
              {device !== DEVICES.desktop
                ? linksCategories.map((link, _key) =>
                  get_item(link.name, `/calentadores/${link.url}`, _key)
                )
                : null}
            </>
          ) : (
            <>
              {get_item(
                "Compra en línea",
                "/compra-en-linea",
                "tag-burger-compra-en-linea"
              )}
              {get_item(
                "Solicita ayuda",
                "/solicita-ayuda",
                "tag-burger-solicita-ayuda"
              )}
              {get_item(
                "Distribuidores",
                "/distribuidores",
                "tag-burger-distribuidores"
              )}
              {get_item(
                "Tu calentador ideal",
                "/tu-calentador-ideal",
                "tag-burger-tu-calentador-ideal"
              )}
              {get_item("Servicio Calorex", "/servicio-calorex", "tag-burger-servicio-calorex")}
              {get_item("Comparador", "/comparador", "tag-burger-comparador")}
              {get_item("Blog", "/blog", "tag-burger-blog")}
              {get_item("Nosotros", "/nosotros", "tag-burger-nosotros")}
              {get_item("Código de Ética", "/codigo-de-etica", "tag-burger-codigo-de-etica")}
              {get_item("Contáctanos", "/contactanos", "tag-burger-contactanos")}
              {get_item("Promociones", "/promociones", "tag-burger-promociones")}
              {get_item("Newsletter", "/newsletter", "tag-burger-newsletter")}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default BurgerMenu
