import React from "react"

const SvgInstagram = props => (
    <svg viewBox="0 0 503.8 503.8" {...props}>
        <defs>
            <clipPath id="prefix__a">
                <path d="M0 0h504v504H0z" fill="none" />
            </clipPath>
        </defs>
        <g data-name="Layer 2">
            <g data-name="Layer 1" clipPath="url(#prefix__a)">
                <path d="M502 148c-1-27-5-45-11-61a123 123 0 00-29-45 124 124 0 00-45-29c-16-6-34-10-61-11s-36-2-104-2L148 2c-27 2-45 5-61 11a123 123 0 00-45 29 123 123 0 00-29 45c-6 16-10 34-11 61s-2 36-2 104l2 104c2 27 5 45 11 61a123 123 0 0029 45 123 123 0 0045 29c16 6 34 10 61 11s36 2 104 2l104-2c27-2 45-5 61-11a129 129 0 0074-74c6-16 10-34 11-61s2-36 2-104l-2-104M252 381a129 129 0 11129-129 129 129 0 01-129 129m134-233a30 30 0 1131-31 30 30 0 01-31 31" />
                <path d="M252 168a84 84 0 1084 84 84 84 0 00-84-84" />
            </g>
        </g>
    </svg>
)

export default SvgInstagram