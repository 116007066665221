import React from 'react'

const CalorexLogoHeader = props => (
  <svg viewBox="0 0 525.66 120.15" {...props}>
    <polygon fill={props.fill != null ? props.fill :"#D31347"} points="0 113.7 0 91.77 16.08 91.77 16.08 96.7 5.66 96.7 5.66 100.12 15.85 100.12 15.85 105.05 5.66 105.05 5.66 108.77 16.08 108.77 16.08 113.7 0 113.7"/>
    <rect fill={props.fill != null ? props.fill :"#D31347"} x="20.68" y="91.77" width="5.06" height="21.93"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m51.52,113.7v-9.24c0-1.94-1.02-2.57-2.63-2.57-1.48,0-2.47.82-3.06,1.55v10.26h-5.07v-21.93h5.07v7.99c.95-1.12,2.83-2.33,5.49-2.33,3.62,0,5.26,2.04,5.26,4.96v11.31h-5.06Z"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m60.66,105.74c0-4.47,3.26-8.32,8.65-8.32s8.68,3.85,8.68,8.32-3.22,8.35-8.68,8.35-8.65-3.88-8.65-8.35m12.13,0c0-2.1-1.25-3.85-3.48-3.85s-3.42,1.74-3.42,3.85,1.22,3.88,3.42,3.88,3.48-1.74,3.48-3.88"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m81.66,117.78l2.2-3.62c1.22,1.32,2.99,1.84,4.83,1.84,1.68,0,4.04-.69,4.04-3.65v-1.22c-1.31,1.55-2.89,2.3-4.7,2.3-3.88,0-7-2.66-7-8.02s3.03-7.99,7-7.99c1.78,0,3.42.69,4.7,2.27v-1.87h5.03v14.47c0,6.48-5.13,7.86-9.07,7.86-2.73,0-5-.69-7.04-2.37m11.08-10.23v-4.24c-.62-.85-1.97-1.41-3.02-1.41-2.01,0-3.49,1.22-3.49,3.52s1.48,3.55,3.49,3.55c1.05,0,2.4-.56,3.02-1.41"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m112.31,113.7v-1.58c-.99,1.18-2.89,1.97-4.93,1.97-2.43,0-5.46-1.64-5.46-5.23,0-3.85,3.03-5.1,5.46-5.1,2.1,0,3.98.66,4.93,1.84v-1.91c0-1.38-1.18-2.3-3.22-2.3-1.58,0-3.22.59-4.51,1.68l-1.81-3.19c2.04-1.74,4.77-2.47,7.2-2.47,3.81,0,7.4,1.41,7.4,6.35v9.93h-5.06Zm0-4.08v-1.41c-.53-.72-1.71-1.12-2.83-1.12-1.35,0-2.53.59-2.53,1.84s1.18,1.84,2.53,1.84c1.12,0,2.3-.4,2.83-1.15"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m122.5,113.7v-15.88h5.06v1.94c1.05-1.28,3.06-2.37,4.97-2.37v4.87c-.3-.07-.72-.13-1.25-.13-1.32,0-3.06.56-3.72,1.45v10.13h-5.06Z"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m156.82,113.7v-1.84c-1.31,1.51-2.93,2.24-4.7,2.24-3.91,0-7.04-2.96-7.04-8.35s3.06-8.32,7.04-8.32c1.74,0,3.42.69,4.7,2.27v-7.92h5.03v21.93h-5.03Zm0-5.52v-4.87c-.66-.85-1.97-1.41-3.06-1.41-2.01,0-3.49,1.48-3.49,3.85s1.48,3.88,3.49,3.88c1.08,0,2.4-.59,3.06-1.45"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m165.96,105.74c0-4.6,3.49-8.32,8.45-8.32,4.7,0,8.12,3.45,8.12,8.85v1.09h-11.31c.29,1.48,1.68,2.83,3.98,2.83,1.12,0,3.06-.49,3.95-1.35l2.14,3.16c-1.58,1.41-4.21,2.1-6.64,2.1-4.9,0-8.68-3.19-8.68-8.35m8.45-4.41c-2.2,0-3.06,1.58-3.26,2.73h6.54c-.13-1.12-.95-2.73-3.29-2.73"/>
    <rect fill={props.fill != null ? props.fill :"#D31347"} x="186.47" y="91.77" width="5.06" height="21.93"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m205.48,105.74c0-4.97,3.72-8.32,8.68-8.32,3.42,0,5.56,1.51,6.51,2.93l-3.29,3.06c-.62-.92-1.64-1.51-2.99-1.51-2.1,0-3.72,1.45-3.72,3.85s1.61,3.88,3.72,3.88c1.35,0,2.37-.66,2.99-1.55l3.29,3.09c-.95,1.38-3.09,2.93-6.51,2.93-4.97,0-8.68-3.35-8.68-8.35"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m223.36,105.74c0-4.47,3.26-8.32,8.65-8.32s8.68,3.85,8.68,8.32-3.22,8.35-8.68,8.35-8.65-3.88-8.65-8.35m12.13,0c0-2.1-1.25-3.85-3.48-3.85s-3.42,1.74-3.42,3.85,1.22,3.88,3.42,3.88,3.48-1.74,3.48-3.88"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m255.55,113.7v-9.17c0-1.94-1.02-2.63-2.6-2.63s-2.5.82-3.09,1.55v10.26h-5.06v-15.88h5.06v1.94c.95-1.12,2.83-2.33,5.49-2.33,3.62,0,5.26,2.1,5.26,5.03v11.25h-5.06Z"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m266.82,113.7v-11.48h-2.63v-4.41h2.63v-.79c0-3.35,2.37-5.59,5.69-5.59,1.71,0,3.49.46,4.57,1.58l-1.87,2.89c-.4-.43-.85-.62-1.58-.62-.95,0-1.74.62-1.74,1.74v.79h3.22v4.41h-3.22v11.48h-5.06Z"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m277.61,105.74c0-4.47,3.26-8.32,8.65-8.32s8.68,3.85,8.68,8.32-3.22,8.35-8.68,8.35-8.65-3.88-8.65-8.35m12.13,0c0-2.1-1.25-3.85-3.48-3.85s-3.42,1.74-3.42,3.85,1.22,3.88,3.42,3.88,3.48-1.74,3.48-3.88"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m299.04,113.7v-15.88h5.06v1.94c1.05-1.28,3.06-2.37,4.97-2.37v4.87c-.3-.07-.72-.13-1.25-.13-1.32,0-3.06.56-3.72,1.45v10.13h-5.06Z"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m313.87,109.43v-7.2h-2.63v-4.41h2.63v-4.34h5.03v4.34h3.22v4.41h-3.22v5.79c0,.92.53,1.61,1.41,1.61.56,0,1.12-.2,1.28-.4l.99,3.82c-.62.59-1.87,1.05-3.75,1.05-3.22,0-4.97-1.61-4.97-4.67"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m334.61,111.66l2.1-3.49c1.18,1.05,3.72,2.11,5.59,2.11,1.51,0,2.14-.49,2.14-1.22,0-2.07-9.24-.03-9.24-6.41,0-2.79,2.4-5.23,6.81-5.23,2.67,0,4.93.85,6.64,2.1l-1.94,3.35c-.95-.92-2.76-1.74-4.67-1.74-1.18,0-2.01.49-2.01,1.15,0,1.84,9.24.03,9.24,6.54,0,3.03-2.66,5.26-7.23,5.26-2.83,0-5.76-.95-7.43-2.43"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m364.04,113.7v-1.91c-.99,1.12-2.83,2.3-5.49,2.3-3.62,0-5.23-2.04-5.23-4.97v-11.31h5.06v9.24c0,1.91.99,2.57,2.63,2.57,1.45,0,2.43-.79,3.03-1.55v-10.26h5.06v15.88h-5.06Z"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m372.88,111.66l2.1-3.49c1.18,1.05,3.72,2.11,5.59,2.11,1.51,0,2.14-.49,2.14-1.22,0-2.07-9.24-.03-9.24-6.41,0-2.79,2.4-5.23,6.81-5.23,2.67,0,4.93.85,6.64,2.1l-1.94,3.35c-.95-.92-2.76-1.74-4.67-1.74-1.18,0-2.01.49-2.01,1.15,0,1.84,9.24.03,9.24,6.54,0,3.03-2.66,5.26-7.23,5.26-2.83,0-5.76-.95-7.43-2.43"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m392.64,109.43v-7.2h-2.63v-4.41h2.63v-4.34h5.03v4.34h3.22v4.41h-3.22v5.79c0,.92.53,1.61,1.41,1.61.56,0,1.12-.2,1.28-.4l.99,3.82c-.62.59-1.87,1.05-3.75,1.05-3.22,0-4.97-1.61-4.97-4.67"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m403.82,105.74c0-4.6,3.49-8.32,8.45-8.32,4.7,0,8.12,3.45,8.12,8.85v1.09h-11.31c.29,1.48,1.68,2.83,3.98,2.83,1.12,0,3.06-.49,3.95-1.35l2.14,3.16c-1.58,1.41-4.21,2.1-6.64,2.1-4.9,0-8.68-3.19-8.68-8.35m8.45-4.41c-2.2,0-3.06,1.58-3.26,2.73h6.54c-.13-1.12-.95-2.73-3.29-2.73"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m435.08,113.7v-9.17c0-1.94-1.02-2.63-2.6-2.63s-2.5.82-3.09,1.55v10.26h-5.06v-15.88h5.06v1.94c.95-1.12,2.83-2.33,5.49-2.33,3.62,0,5.26,2.1,5.26,5.03v11.25h-5.06Z"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m446.33,109.43v-7.2h-2.63v-4.41h2.63v-4.34h5.03v4.34h3.22v4.41h-3.22v5.79c0,.92.53,1.61,1.41,1.61.56,0,1.12-.2,1.28-.4l.99,3.82c-.62.59-1.87,1.05-3.75,1.05-3.22,0-4.97-1.61-4.97-4.67"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m467.93,113.7v-1.58c-.99,1.18-2.89,1.97-4.93,1.97-2.43,0-5.46-1.64-5.46-5.23,0-3.85,3.03-5.1,5.46-5.1,2.1,0,3.98.66,4.93,1.84v-1.91c0-1.38-1.18-2.3-3.22-2.3-1.58,0-3.22.59-4.51,1.68l-1.81-3.19c2.04-1.74,4.77-2.47,7.2-2.47,3.81,0,7.4,1.41,7.4,6.35v9.93h-5.06Zm0-4.08v-1.41c-.53-.72-1.71-1.12-2.83-1.12-1.35,0-2.53.59-2.53,1.84s1.18,1.84,2.53,1.84c1.12,0,2.3-.4,2.83-1.15"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m478.12,113.7v-21.93h5.06v7.92c1.25-1.58,2.96-2.27,4.7-2.27,3.95,0,7,3.12,7,8.32s-3.09,8.35-7,8.35c-1.78,0-3.39-.72-4.7-2.24v1.84h-5.06Zm8.09-4.08c2.01,0,3.52-1.48,3.52-3.88s-1.51-3.85-3.52-3.85c-1.08,0-2.4.59-3.03,1.45v4.87c.62.85,1.94,1.41,3.03,1.41"/>
    <rect fill={props.fill != null ? props.fill :"#D31347"} x="498.99" y="91.77" width="5.06" height="21.93"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m508.13,105.74c0-4.6,3.49-8.32,8.45-8.32,4.7,0,8.12,3.45,8.12,8.85v1.09h-11.31c.29,1.48,1.68,2.83,3.98,2.83,1.12,0,3.06-.49,3.95-1.35l2.14,3.16c-1.58,1.41-4.21,2.1-6.64,2.1-4.9,0-8.68-3.19-8.68-8.35m8.45-4.41c-2.2,0-3.06,1.58-3.26,2.73h6.54c-.13-1.12-.95-2.73-3.29-2.73"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m34.8,10.36c5.33,4.77,23.6,21.02,26.43,23.55v35.09H8.85v-35.14c2.78-2.51,20.65-18.71,25.95-23.51m-3.47-9.04L1.75,28.13c-1.05.97-1.68,2.36-1.68,3.8v40.56c0,2.84,2.3,5.13,5.13,5.13h59.7c2.82,0,5.12-2.29,5.12-5.13V31.93c0-1.44-.62-2.85-1.72-3.84L38.19,1.29c-1.95-1.74-4.91-1.72-6.86.03"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m34.82,29.76c-2.45,2.21-10.69,9.68-11.96,10.82v16.18h24.15v-16.16c-1.33-1.17-9.75-8.67-12.19-10.85"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m399.08,45.51c0-9.61-6.34-15.57-16.55-15.57h-33.97v47.52h10.67v-16.97h17.27l11.96,16.97h12.5l-12.5-18.05c6.79-1.68,10.63-6.84,10.63-13.9m-18.01,5.69h-21.85v-11.4h21.81c6.44,0,7.21,2.92,7.21,5.68s-.84,5.72-7.17,5.72"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m186.52,28.13c-2.21,0-4.36,1.02-5.42,2.71l-28.19,46.24h12.69l5.16-8.67h31.54l5.16,8.67h12.69l-28.2-46.24c-1.03-1.69-3.2-2.71-5.41-2.71m-10.55,31.54s10.18-17.16,10.55-17.8c.39.64,5.47,9.23,10.56,17.8h-21.12Z"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m308.29,28.37c-18.68,0-29.03,4.04-29.03,24.63s10.35,24.62,29.03,24.62,29.04-4.03,29.04-24.62-10.37-24.63-29.04-24.63m0,39c-11.53,0-17.55-.62-17.55-14.36s6.02-14.38,17.55-14.38,17.55.64,17.55,14.38-6,14.36-17.55,14.36"/>
    <path fill={props.fill != null ? props.fill :"#D31347"} d="m139.84,61.2c-2.27,5.82-7.78,6.17-16.37,6.17-11.53,0-17.55-.62-17.55-14.36s6.02-14.38,17.55-14.38c8.58,0,14.09.35,16.36,6.17l10.61-4.69c-3.88-9.51-13.15-11.73-26.98-11.73-18.68,0-29.03,4.04-29.03,24.63s10.35,24.62,29.03,24.62c13.83,0,23.1-2.22,26.98-11.73l-10.61-4.69Z"/>
    <polygon fill={props.fill != null ? props.fill :"#D31347"} points="237.2 29.93 236.17 29.93 226.4 29.93 226.4 77.45 273.5 77.45 273.5 76.42 273.5 66.65 237.2 66.65 237.2 29.93"/>
    <polygon fill={props.fill != null ? props.fill :"#D31347"} points="422.7 58.25 447.3 58.25 447.3 57.23 447.3 47.45 422.7 47.45 422.7 40.73 457.47 40.73 457.47 39.71 457.47 29.93 411.91 29.93 411.91 77.45 459.01 77.45 459.01 76.42 459.01 66.65 422.7 66.65 422.7 58.25"/>
    <polygon fill={props.fill != null ? props.fill :"#D31347"} points="525.66 29.93 511.59 29.93 495.62 46.43 479.65 29.93 465.58 29.93 488.58 53.7 465.58 77.45 479.69 77.45 495.62 60.98 511.56 77.45 525.66 77.45 502.66 53.7 525.66 29.93"/>
  </svg>
)

export default CalorexLogoHeader